<template lang="pug">
    div(:class="{ isTrabalhe }").abas
        .head
            ul
                li(v-for="tab in tabs", @click="changeAtivo(tab.index)", :class="{ ativo: ativo == tab.index }", v-html="tab.title") 
        .body
            component(:is="item.component", :contents="item.contents", v-for="item, index in mappedComponents", :key="`${keyTab}-${item.type}-${index}`")
</template>

<script>
import { props, textComponents as components, textDictionary } from '@/mixins/component'

export default {
    name: "component-abas",
    props,
    components,
    data() {
        return {
            tabQuery: null
        }
    },
    computed: {
        mappedComponents() {
            if (this.tabActive != null)
                return this.tabActive.content.map(item => {
                    return { component: this.$options.components[textDictionary[item.type]], contents: item, type: item.type }
                })
            return []
        },
        tabs() {
            return this.contents.content || []
        },
        tabActive() {
            return this.tabs.find(tab => tab.index == this.ativo) || null
        },
        tabsIdentifier() {
            return this.contents.id
        },
        keyTab() {
            if (this.tabActive != null)
                return this.tabActive.title
            return ``
        },
        isTrabalhe() {
			return this.$route.name == 'Trabalhe'
		},
        ativo: {
            get() {
                return this.$store.getters.tabs(this.tabsIdentifier)
            },
            set(value) {
                this.$store.commit('setTabs', { name: this.tabsIdentifier, value })
            }
        }
    },
    mounted() {
        if (this.$route.query.tabQuery)
            this.changeAtivo(this.$route.query.tabQuery || null)
    },
    methods: {
        changeAtivo(id) {
            this.ativo = id
        }
    },
}
</script>

<style lang="stylus" scoped src="./Abas.styl"></style>
